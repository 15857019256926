.progressComponentContainer {
    display: flex;
    flex-direction: column;
    margin-top: 40px;
}
.progress {
    margin-top: 200px;
    align-self: center;
}

.button {
    background-color: #352F6C;
    color: white;
    width: 180px;
}

.modalContainer {
    border: 3px solid #352F6C;
    border-radius: 10px;
    background-color: #F4F2FA;
    display: flex;
    flex-direction: column;
    padding: 40px;
    text-align: center;
    align-items: center;
}

.modalButtonContainer {
    display: flex;
    flex-direction: row;
    gap: 20px;
    margin-top: 40px;
}

.itemContainer {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    gap: 50px;
}
