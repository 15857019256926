.dashboardContainer {}

.contentContainer {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: 12px;
    margin-top: 12px;
}

.graphContainer {
    background-color: white;
    width: 100%;
    min-height: 300px;
    border-radius: 4px;
    padding: 12px;

}