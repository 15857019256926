.autoCompleteContainer {
    position: relative;
    flex: 1;
    width: 100%;
}
.options {
    background-color: white;
    
    padding: 12px 8px;
    width: 100%;
    text-align: start;
    pointer-events: all;
    border: none;
    vertical-align: center;
}

.inputField {
    background-color: white;
    border-radius: 7;
    border: none;
    outline: none;
    border-radius: 5px;
    height: 50px;
   
}

.optionsContainer {
    position: absolute;
    width: 100%;
    max-height: 400px;
    border-radius: 10px;
    padding: 10px 5px;
    z-index: 2;
    display: flex;
    flex-direction: column;
    background-color: white;
    pointer-events: all;
}

.optionsContainer::-webkit-scrollbar {
    display: none;
}

.optionsContainer {
    -ms-overflow-style: none;
    scrollbar-width: none;
}
