.manageUserContainer {
    
}
.tabHederContainer {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    width: 100%;
    align-items: center;
}

.filterContainer {
    margin-top: 40px;
    padding-top: 10px;
    padding-bottom: 10px;
    background-color: #F5F3FA;
}
