.button {
    height: 40px;
    background-color: #352F6C;
    color: white;
    font-weight: 700;
    border-radius: 5px;
    border: #352F6C;
    width: 20%;
    margin-left: 80%;
    align-self: flex-end;
    margin-top: 40px;
}

.documentListContainer {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    color: #352F6C;
    gap: 20px;
}

.documentItem {
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 10px;
}

.documentName {
    font-weight: 500;
    font-size: 15px;
    cursor: pointer;
    text-decoration: underline;
}

.nonFinRelationLabel {
    font-weight: 600;
    margin-top: 40px;
    margin-bottom: 10px;
}

.nonFinRelationClickableLabel {
    font-weight: 600;
    margin-top: 40px;
    margin-bottom: 10px;
    text-decoration: underline;
    cursor: pointer;

}