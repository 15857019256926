.advisorLeadsContainer {
    background-color: white;
    border-radius: 12px;
    padding: 24px;
}

.performanceTypeContainer {
    display: flex;
    gap: 20px;
}

.selectedPerformanceButton {
    padding: 8px 20px;
    border-radius: 4px;
    cursor: pointer;
    background-color: rgba(53, 47, 108, 0.1);
    transition-duration: 0.5s;
    font-weight: 500;
}

.selectedPerformanceButton:hover {
    background-color: rgba(53, 47, 108, 0.06);

}

.unselectedPerformanceButton {
    padding: 8px 20px;
    border-radius: 4px;
    cursor: pointer;
    font-weight: 500;
}

.datePicker {
    margin-left: auto;
    padding: 0 24px;
}

.barsContainer {
    width: 100%;
    background-color: #cccccc28;
    margin-top: 24px;
    display: flex;
    border-radius: 4px;
    overflow: hidden;
}

.bars {
    height: 50px;
    background-color: cadetblue;
    border-right: 1px solid rgba(0, 0, 0, 0.171);
}

.legendGrid {
    display: grid;
    grid-template-columns: repeat(5, minmax(60px, 1fr));
}

.legendContainer {
    margin-top: 40px;
    display: flex;
    flex-direction: column;
    gap: 8px;
}