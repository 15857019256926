.dashboardContainer {
    color: #352F6C;
    display: flex;
    gap: 24px;
    flex-direction: column;
}

.topContainer {
    height: 60dvh;

    overflow: hidden;
    display: flex;
    gap: 48px;
}

.notifications {
    flex: 1;
    overflow: scroll;
    overflow-x: hidden;
}

.notifications::-webkit-scrollbar {
    display: none;
}

.notifications {
    -ms-overflow-style: none;
    scrollbar-width: none;
}

.rescheduled {
    flex: 1;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
}