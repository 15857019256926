.modalContainer {
    border: 3px solid #352F6C;
    border-radius: 10px;
    background-color: #F4F2FA;
    display: flex;
    flex-direction: column;
    padding: 40px;
    text-align: center;
    align-items: center;
}

.modalTitle {
    width: 250px;
    color: #352F6C;
    font-weight: 300;
}

.infoContainer {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    background-color: #352F6C;
    grid-gap: 1px;
    margin-top: 20px;
    margin-bottom: 20px;

}

.infoItem {
    padding: 10px 10px;
    background-color: #fff;
    border: 10px;
}

.inputField {
    background-color: white;
    border-radius: 7;
    border: none;
    outline: none;
    border-radius: 5px;
    height: 50px;
    padding: 10px;
    width: 100%;
}

.buttonContainer {
    display: flex;
    flex-direction: row;
    gap: 30px;
    margin-top: 20px;
}

.secondaryButton {
    background-color: rgba(53, 47, 108, 0.5);
    color: white;
    padding: 5px 25px;
    cursor: pointer;
    border-radius: 5px;
}

.primaryButton {
    background-color: #352F6C;
    color: white;
    padding: 5px 25px;
    cursor: pointer;
    border-radius: 5px;
}