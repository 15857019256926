.detailContainer {
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    width: 100%;
    gap: 20px;
    padding: 30px;
}

.label {
    font-weight: 300;
    font-size: 15px;
    color: #352F6C;
}

.value {
    font-weight: 700;
    font-size: 15px;
    color: #352F6C;
}