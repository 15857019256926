.progressComponentContainer {
    display: flex;
    flex-direction: column;
    margin-top: 20px;
}
.progress {
    margin-top: 200px;
    align-self: center;
}

.inputButtonContainer {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: flex-end;
}