.addSingleLeadContainer {
    min-height: 100vh;
    display: flex;
    flex-direction: column;
}

.headerContainer {
    display: flex;
    flex-direction: row;
    width: 100%;
    justify-content: space-between;
}

.formContainer {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    gap: 20px;
    margin-top: 40px;
    margin-bottom: 40px;
}

.inputField {
    background-color: white;
    border-radius: 7;
    border: none;
    outline: none;
    border-radius: 5px;
    height: 50px;
    padding: 10px;
    width: 100%;
}

.label {
    font-size: 12px;
    margin-bottom: 2px;
    color: #352F6C;
    font-weight: 300;
}

.button {
    align-self: flex-end;
    width: 150px;

}