.inboundBoxContainer {
    scrollbar-width: none;
    width: 100%;
    height: 100%;
    overflow-y: scroll;
}

.headingButtonContainer {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
}

.filterContainer {
    margin-top: 40px;
    margin-bottom: 40px;
}

.progressComponentContainer {
    display: flex;
    flex-direction: column;
}
.progress {
    margin-top: 200px;
    align-self: center;
}