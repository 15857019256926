.detailContainer {
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    width: 100%;
    gap: 20px;
    padding: 20px;
}

.comment {
    color: #352F6C;
    font-weight: 500;
}