.notificationsSection {
    background-color: white;
    height: 100%;
    padding: 12px 24px;
    border-radius: 12px;
}

.sectionTitle {
    position: sticky;
    top: 24px;
}

.notificationListContainer {
    display: flex;
    flex-direction: column;
    gap: 8px;
    margin-top: 8px;
    height: 100%;
    border-radius: 12px;
}

.dayWiseNotificatonBlockContainer {}

.notificationContainer {
    background-color: white;
    padding: 8px 12px;
    border-radius: 4px;
}

.timeStamp {
    font-size: 0.8rem;
    text-align: right;
}