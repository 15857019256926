.resourcesTabContainer {
    min-height: 100vh;
    display: flex;
    flex-direction: column;
}

.headerContainer {
    display: flex;
    flex-direction: row;
    width: 100%;
    justify-content: space-between;
}


.buttonsContainer {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-end;
    gap: 20px;
    margin-top: 40px;
}

.modalContainer {
    border: 3px solid #352F6C;
    border-radius: 10px;
    background-color: #F4F2FA;
    display: flex;
    flex-direction: column;
    padding: 40px;
    text-align: center;
    align-items: center;
}

.modalButtonContainer {
    display: flex;
    flex-direction: row;
    gap: 20px;
    margin-top: 40px;
}

.inputField {
    background-color: white;
    border-radius: 7;
    border: none;
    outline: none;
    border-radius: 5px;
    height: 50px;
    padding: 10px;
    width: 100%;
}