.popupContainer {
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
}

.contestImage {
    max-width: 35vw;
}

.closeButton {
    position: absolute;
    right: 0;
    top: 0;
    margin: 30px;
    cursor: pointer;
}

.closeButton:hover {
    scale: 1.2;
}