.authPageContainer {
    display: flex;
    height: 100vh;
}

.authIllustrationContainer {
    flex: 0.6;
    background-color: #f4f2fa;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 120px;
}

.authContentText {
    font-weight: 600;
    color: #352f6c;
    width: 550px;
    font-size: 1.2rem;
}

.authIllustration {
    width: 600px;
}

.authFormContainer {
    flex: 0.4;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.authForm {
    width: 70%;
    gap: 20px;
   
    display: flex;
    flex-direction: column;
}
