.tiCLaimDetailContainer {
    min-height: 100vh;
    display: flex;
    flex-direction: column;
}

.formContainer {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    gap: 20px;
    margin-top: 40px;
}

.inputField {
    background-color: white;
    border-radius: 7;
    border: none;
    outline: none;
    border-radius: 5px;
    height: 50px;
    padding: 10px;
    width: 100%;
}

.label {
    font-size: 12px;
    margin-bottom: 2px;
    color: #352F6C;
    font-weight: 300;
}

.documentItem {
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 10px;
    margin-top: 20px;
}

.documentName {
    font-weight: 500;
    font-size: 15px;
    cursor: pointer;
    text-decoration: underline;
}

.button {
    width: 250px;
    height: 35px;
}