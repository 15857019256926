.homePageContainer {
    display: flex;
    background-color: #F5F3FA;
    overflow-y: hidden;
    max-height: 100vh;
}

.userName {
    width: 100%;
    font-weight: 300;
    text-align: center;
    color: white;
    font-size: 25px;
}

.homepageSideBarContainer {
    height: 100vh;
    max-height: 100vh;
    flex:0.2;
    background-color: #352F6C;
    display: flex;
    flex-direction: column;
    gap: 16px;
    overflow-x: scroll;
    -ms-overflow-style: none; 
    scrollbar-width: none;
}

.homepageSideBarContainer::-webkit-scrollbar {
    display: none;
}

.homePageTabContentContainer {
    flex:0.8;
    overflow-y: scroll;
    padding: 50px;
}

.dp {
    width: 120px;
    height: 120px;
    margin: 60px auto -20px;
}

.activeSideBarButton {
    color: white;
    padding: 8px 40px;
    cursor: pointer;
    transition-duration: 0.5s;
    display: flex;
    gap: 12px;
    align-items: center;
    cursor: pointer;
    background-color: #ffffffc0;
    border-radius: 5px;
    margin: 0 15px;
}

.inActiveSideBarButton {
    color: white;
    padding: 8px 40px;
    cursor: pointer;
    transition-duration: 0.5s;
    display: flex;
    gap: 12px;
    align-items: center;
    cursor: pointer;
}

.sideBarButton:hover{
    background-color: #665dbb;
}

.sideBarButtonText {
    font-weight: 500;
}