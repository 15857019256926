.containerStyle {
    width: 100%;
    background-color: white;
    border-radius: 5px;
    padding: 10px;
    display: flex;
    flex-direction: column;
    gap: 5px;
}

.labelStyle {
    color: #352F6C;
    font-size: 12px;
    font-weight: 300;
}

.inputStyle {
    border: none;
    outline: none;
}