.progressComponentContainer {
    display: flex;
    flex-direction: column;
    margin-top: 40px;
}
.progress {
    margin-top: 200px;
    align-self: center;                  
}

.tabsSearchContainer {
    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
}

.modalContent {
    background-color: white;
    width: 100%;
    margin-top: auto;
    padding: 24px;
}

.callIcon {
    width: 30px;
    height: 30px;
    padding: 4px;
    border-radius: 15px;
    transition-duration: 0.5s;
}