.detailCodeContainer {
    display: flex;
    flex-direction: column;
    width: 100%;
    gap: 20px;
}

.detailContainer {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    width: 100%;
    gap: 20px;
    padding: 30px;
}

.label {
    font-weight: 300;
    font-size: 15px;
    color: #352F6C;
}

.value {
    font-weight: 700;
    font-size: 15px;
    color: #352F6C;
}

.codeFormContainer {
    display: flex;
    flex-direction: row;
    padding: 0 30px 30px 30px;
    width: 100%;
    justify-content: space-between;
    gap: 40px;
}

.label {
    font-size: 12px;
    margin-bottom: 2px;
    color: #352F6C;
    font-weight: 300;
}

.starsContainer {
    display: flex;
    flex-direction: row;
    gap: 15px;
}

.formContainer {
    flex: 1;
    gap: 20px;
    display: flex;
    flex-direction: column;
    height: 100%;
    justify-content: space-between;
    align-items: baseline;
    margin-top: auto;
}

.inputField {
    background-color: #F4F2FA;
    border-radius: 7;
    border: none;
    outline: none;
    border-radius: 5px;
    height: 50px;
    padding: 10px;
    width: 100%;
}

.submitButton {
    width: 200px;
    height: 40px;
    background-color: #352F6C;
    color: white;
    font-weight: 700;
    border-radius: 5px;
    margin-top: auto;
}
