.adminCompleteDetail {
    display: flex;
    flex-direction: column;
}

.headerContainer {
    display: flex;
    flex-direction: row;
    width: 100%;
    justify-content: space-between;
    padding: 0 20px 0 0;
}

.qrMessageContainer {
    display: flex;
    flex-direction: row;
    align-items: center;
}

.messageContainer {
    padding: 10px;
    background-color: #352F6C;
    color: white;
    border-radius: 10px 0 0 10px;
    font-size: 10px;
    font-weight: 500;
    margin-top: 20px;
    width: 120px;
    text-align: center;
}

.qrCodeContainer {
    border-radius: 10px;
    border: 5px solid #352F6C;
    padding: 5px;
    width: 150px;
    height: 150px;
}

.formListContainer {
    display: flex;
    flex-direction: column;
}

.formContainer {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    gap: 20px;
}

.inputField {
    background-color: white;
    border-radius: 7;
    border: none;
    outline: none;
    border-radius: 5px;
    height: 50px;
    padding: 10px;
    width: 100%;
}

.inputFieldSpecial {
    background-color: #fac739;
    border-radius: 7;
    border: none;
    outline: none;
    border-radius: 5px;
    height: 50px;
    padding: 10px;
    width: 100%;
}

.label {
    font-size: 12px;
    margin-bottom: 2px;
    color: #352F6C;
    font-weight: 300;
}

.buttonsTypesContainer {
    flex: 1;
    display: flex;
    flex-direction: column;
    width: 100%;
    margin-top: 40px;
}

.interestedButtonContainer {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    gap: 20px;
}

.twoButtonContainer {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-end;
    gap: 20px;
    margin-top: 40px;
}

.nfRelationLabel {
    color: #352F6C;
    font-weight: 600;
    font-size: 16px;
    margin-bottom: 10px;
}

.yesNoContainer {
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 15px;
    color: rgba(53, 47, 108, 0.5);
    font-weight: 500;
}

.nonFinListContainer {
    width: 100%;
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    gap: 20px;
    margin-top: 10px;
}

.cdTabTitle {
    font-size: 18px;
    color:#352F6C;
    font-weight: 600;
    text-decoration: underline;
    margin-bottom: 10px;
    margin-top: 20px;
}

.documentListContainer {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    color: #352F6C;
    gap: 20px;
}

.documentItem {
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 10px;
}

.documentName {
    font-weight: 500;
    font-size: 15px;
    cursor: pointer;
    text-decoration: underline;
}

.nonFinRelationLabel {
    font-weight: 600;
    margin-top: 40px;
    margin-bottom: 10px;
}

.singleButtonContainer {
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
}
